@font-face { font-family: L4719a; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAO8AAsAAAAABdAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPQAAAFYqRzbGY21hcAAAAYQAAABKAAABcOEoo6pnbHlmAAAB0AAAACgAAAAoTOokF2hlYWQAAAH4AAAALgAAADYn5xBZaGhlYQAAAigAAAAbAAAAJADxAEBobXR4AAACRAAAAAgAAAAIAGQAAGxvY2EAAAJMAAAABgAAAAYAFAAAbWF4cAAAAlQAAAAeAAAAIAENABVuYW1lAAACdAAAASkAAAIWm5e+CnBvc3QAAAOgAAAAGwAAACxqOAEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGQwYpzAwMrAwGDP4AYk+aC0AQMLgyQDAxMDKzMDVhCQ5prC4PCA4QEDQwqQywkmGRgYQQQA+uoGagAAAHic7ZCxDYAwEAPPykOBGIEqNbNQsb/YJPkY2CKWzpJPXz2wACU5kwDdiJErrewLm31QfRPDP7T2d+5wo5WZ3X18y796QR0aJglRAAAAAQAAAAAAiwBiAAgAADcHFyMVMwcXN2kKEG9vEAoiYgoRDhAKIQAAeJxjYGRgYABiHgXvzfH8Nl8ZuBlSgCIMT+ReeiPTDN0MSUCSg4EJxAEA/CkIngAAeJxjYGRgYEhhYICQ/28ydDMwMqACJgBIQgMwAAAAAAAAZAAAAAAAAAAUAAB4nGNgZGBgYGLgZADRIBYDAxcQMjD8B/MZAAl4ASMAAHicZZA9bsJAFITHYEgCUoIUKSmzVQoimZ+SA0BPQZfCmLUxsr3WekGiywlyhBwhp4hyghwoY/NoYC0/fzNv3u7KAAb4hYd6ebhtar1auKE6cZv0IOyTn4U76ONFuEt/KNzDG6bCfTzinTt4/h2dAUrhFu7xIdym/ynsk7+EO3jCt3CX/o9wDyv8Cffx6g3TyBSxKdxSJ/sstGd5/q60rVJTqEkwPlsLXWgbOr1R66OqDsnUuVjF1uRqzq7OMqNKa3Y6csHWuXI2GsXiB5HJkSKCQYG4qQ5LaCTYI0MIe9W91CumLSr6tVaYIMD4KrVgqmiSIZXGhsk1jqwVDjxtStcxrfhazuSkucxq3iQjK/7vurejE9EPsG2mSsww4hNf5IPmDvk/PRFeqAAAAHicY2BigABGBuyAiZGJkZmBKdOAgQEAAzIAqAA=) format('woff');font-weight: normal;font-style: normal;}
.swiper-button-next--right:after, .swiper-button-next--left:after  {
  position: relative;
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: L4719a;
  font-weight: normal;
  content: '\E000';
  font-size: 16px;
  opacity: 1;
  width: 24px;
  height: 24px;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
}
.swiper-button-next--right:after {
  left: 50px;
  top: 6px;
}
.swiper-button-next--left:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  right: 70px;
}
.swiper-button-next--left, .swiper-button-next--right {
  position: absolute;
  outline: none;
  cursor: pointer;
  bottom: 0;
  left: 50%;
  z-index: 1;
}
.swiper-button-next--left.swiper-button-disabled, .swiper-button-next--right.swiper-button-disabled {
    opacity: .6;
  }
.swiper-button-next--left:hover:not(.swiper-button-disabled).swiper-button-next--left:after {
    color: #6CBBE7;
  }
.swiper-button-next--right:hover:not(.swiper-button-disabled).swiper-button-next--right:after {
    color: #6CBBE7;
  }
.youtube_slide__pagination.swiper-pagination {
  position: absolute;
  bottom: 4px !important;
}
.youtube__slider {
  width: 100%;
}
@media(min-width: 1540px) {
.youtube__slider {
    overflow: hidden
}
  }
.youtube__slider .profileCard {
    margin-bottom: 2em;
  }
@media(min-width: 571px){
  .youtube__slider {
    padding: 2em 1em 3em; 
    margin: -2em -1em -2em;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: relative;
  }
}