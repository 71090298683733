@font-face { font-family: H6067c; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAPYAAsAAAAABfgAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPQAAAFYpWjYCY21hcAAAAYQAAABKAAABcOEoo6pnbHlmAAAB0AAAAEsAAABQqPoU7mhlYWQAAAIcAAAALAAAADYnew+7aGhlYQAAAkgAAAAbAAAAJAA/ACBobXR4AAACZAAAAAgAAAAIAB4AAGxvY2EAAAJsAAAABgAAAAYAKAAAbWF4cAAAAnQAAAAeAAAAIAENACVuYW1lAAAClAAAASkAAAIWm5e+CnBvc3QAAAPAAAAAFwAAACxqNwEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGTgZ5zAwMrAwCDMIAokWaA0HwMjAzsDAxMDKzMDVhCQ5prC4PCA4QEDgxwDSKEcWJgRRAAApT0EuQAAAHic7ZCxDYAwEAPPykOBGIEqNbNQsb/YJPkY2CKWzpJPXz2wACU5kwDdiJErrewLm31QfRPDP7T2d+5wo5WZ3X18y796QR0aJglRAAB4nBWMMQrAMAzEfLYvfkFIp2QodE2glAz9/7/qCoQ2CeRnSJdDZK8a58Q17dkvWo2h+26dKFRzVQKuYBoFhMHc6YwsoZ6jD76RA6QAeJxjYGRgYADiiJrslfH8Nl8ZuBnkgCIMT+ReOiLTDPJgcQ4GJhAHAAoYCFJ4nGNgZGBgkGNggJD//zPIMzAyoAImADQMAl4AAAAAAAAeAAAAAAAAACgAAHicY2BkYGBgYpBkANEgFgMDFxAyMPwH8xkACwgBMwAAeJxlkD1uwkAUhMdgSAJSghQpKbNVCiKZn5IDQE9Bl8KYtTGyvdZ6QaLLCXKEHCGniHKCHChj82hgLT9/M2/e7soABviFh3p5uG1qvVq4oTpxm/Qg7JOfhTvo40W4S38o3MMbpsJ9POKdO3j+HZ0BSuEW7vEh3Kb/KeyTv4Q7eMK3cJf+j3APK/wJ9/HqDdPIFLEp3FIn+yy0Z3n+rrStUlOoSTA+WwtdaBs6vVHro6oOydS5WMXW5GrOrs4yo0prdjpywda5cjYaxeIHkcmRIoJBgbipDktoJNgjQwh71b3UK6YtKvq1VpggwPgqtWCqaJIhlcaGyTWOrBUOPG1K1zGt+FrO5KS5zGreJCMr/u+6t6MT0Q+wbaZKzDDiE1/kg+YO+T89EV6oAAAAeJxjYGLAD5gYmRiZGZgyDRgYAAMPAKcA) format('woff');font-weight: normal;font-style: normal;}
  .phone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: .2s ease;
  transition: .2s ease;
  text-decoration: none;
}
  .phone__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-transition: .2s ease;
    transition: .2s ease;
    text-decoration: none;
  }
  .phone__container.phone__container--promo {
      color: #ffffff;
    }
  .phone__container.phone__container--promo .phone, .phone__container.phone__container--promo .phone__number {
        font-weight: 900;
        font-size: 40px;
      }
  .phone__container.phone__container--promo .phone__description {
        font-size: 20px;
        margin-top: -5px;
      }
  .phone__container.phone__container--default .phone {
        line-height: 26px;
        font-size: 24px;
        font-family: 'Noah';
        font-weight: 900;
        color: #6CBBE7;
      }
  .phone__container.phone__container--default .phone__number {
        line-height: 26px;
        font-size: 24px;
        font-family: 'Noah';
        font-weight: 900;
        color: #6CBBE7;
      }
  .phone__container.phone__container--default .phone__description {
        color: #78787A;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
      }
  .phone__container.phone__container--footer .phone {
        line-height: 26px;
        font-size: 24px;
        font-family: 'Kinetika';
        font-weight: 700;
        color: #6CBBE7;
      }
  .phone__container.phone__container--footer .phone__number {
        line-height: 26px;
        font-size: 24px;
        font-family: 'Kinetika';
        font-weight: 700;
        color: #6CBBE7;
      }
  .phone__container.phone__container--footer .phone__description {
        color: #78787A;
        font-size: 13px;
        font-family: 'Roboto';
        line-height: 16px;
        letter-spacing: .5px;
        font-weight: 300;
      }
  .phone__container.phone__container--header {
      text-align: right;
    }
  .phone__container.phone__container--header .phone:after {
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-family: H6067c;
          font-weight: normal;
          content: '\E000';
          display: none;
          font-size: 14px;
          position: relative;
        }
  .phone__container.phone__container--header .phone {
        line-height: 1;
        font-size: 0px;
        font-family: 'Noah';
        font-weight: 900;
        color: #6CBBE7;
      }
  .phone__container.phone__container--header .phone:after {
          display: block;
          background: rgba(139, 211, 251, 0.12);
          border-radius: 4px;
          cursor: pointer;
          padding: 7px 8px;
        }
  @media(min-width: 571px) {
  .phone__container.phone__container--header .phone {
          font-size: 24px;
          line-height: 24px;
          margin-right: 0
      }
          .phone__container.phone__container--header .phone:after {
            display: none;
          }
        }
  .phone__container.phone__container--header .phone__number {
        line-height: 0;
        font-size: 0px;
        font-family: 'Kinetika';
        font-weight: 600;
        color: #6CBBE7;
      }
  .phone__container.phone__container--header .phone__number:after {
          display: block;
          background: rgba(216, 171, 94, 0.12);
          border-radius: 4px;
          cursor: pointer;
          padding: 7px 8px;
        }
  @media(min-width: 571px) {
  .phone__container.phone__container--header .phone__number {
          font-size: 24px;
          line-height: 24px;
          margin-right: 0
      }
        }
  .phone__container.phone__container--header .phone__description {
        font-family: 'Roboto';
        color: #272729;
        font-size: 13px;
        letter-spacing: .5px;
        font-weight: 300;
      }
  @media(max-width: 996px) {
  .phone__container.phone__container--header .phone__description {
          display: none
      }
        }
  .phone__description {
    color: #78787A;
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
  }
  .phone--mobile {
    text-decoration: none;
    display: none;
  }
  @media(max-width: 570px) {
  .phone--mobile {
      display: block
  }
    }
  .phone--mobile:after {
      content: '';
      position: relative;
      vertical-align: middle;
      font-weight: 900;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: H6067c;
      font-weight: normal;
      content: '\E000';
      color: #272729;
      font-size: 17px;
      margin-right: 20px;
    }
  .phone--mobile__white:after {
        color: #ffffff;
      }
  /**Options**/
  .phone__menu {
    color: #ffffff;
    margin-bottom: 8px;
  }
  .phone__menu:hover .phone__number {
      color: #ffffff;
    }
  .phone__menu .phone__number {
      font-size: 24px;
      font-weight: 500;
    }
  .phone__menu .phone__description {
      margin-top: -2px;
      font-weight: 300;
      font-size: 12px;
    }
  .phone__consultation .phone__number {
      font-size: 24px;
      line-height: 26px;
      font-family: 'Noah';
      font-weight: bold;
      color: #272729;
    }
  .phone__consultation .phone__description {
      color: #78787A;
      font-weight: 200;
      font-size: 16px;
      line-height: 21px;
      margin-top: 4px;
    }
  .phone.phone--jc_center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .phone.phone--jc_start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .phone.phone--jc_end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }