@font-face { font-family: Ic1f50; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAOsAAsAAAAABdAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPAAAAFYpvzZWY21hcAAAAYAAAABKAAABcOEoo6pnbHlmAAABzAAAACgAAAAoJ4OZs2hlYWQAAAH0AAAALAAAADYncA/9aGhlYQAAAiAAAAAYAAAAJABRAD9obXR4AAACOAAAAAgAAAAIADwAAGxvY2EAAAJAAAAABgAAAAYAFAAAbWF4cAAAAkgAAAAeAAAAIAENABVuYW1lAAACaAAAASkAAAIWm5e+CnBvc3QAAAOUAAAAFwAAACxqNwEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGSQY5zAwMrAwKDGoAUkOaC0DAMTAz8DkGBlZsAKAtJcUxgcHjA8YGCwAXJZwSQDAyOIAADJ0gVyeJztkLENgDAQA8/KQ4EYgSo1s1Cxv9gk+RjYIpbOkk9fPbAAJTmTAN2IkSut7AubfVB9E8M/tPZ37nCjlZndfXzLv3pBHRomCVEAAAABAAAAAAAUADwACAAANwcXIxUzBxc3DwIDEBADAgQ8AgICAgIFAAB4nGNgZGBgAOKo2iTReH6brwzcDDZAEYYnci9dkGkGEbA4BwMTiAMA9YkH73icY2BkYGCwYWCAkyIMjAyogAkADvYAkQAAAAAAPAAAAAAAAAAUAAB4nGNgZGBgYGLgZADRIBYDAxcQMjD8B/MZAAl4ASMAAHicZZA9bsJAFITHYEgCUoIUKSmzVQoimZ+SA0BPQZfCmLUxsr3WekGiywlyhBwhp4hyghwoY/NoYC0/fzNv3u7KAAb4hYd6ebhtar1auKE6cZv0IOyTn4U76ONFuEt/KNzDG6bCfTzinTt4/h2dAUrhFu7xIdym/ynsk7+EO3jCt3CX/o9wDyv8Cffx6g3TyBSxKdxSJ/sstGd5/q60rVJTqEkwPlsLXWgbOr1R66OqDsnUuVjF1uRqzq7OMqNKa3Y6csHWuXI2GsXiB5HJkSKCQYG4qQ5LaCTYI0MIe9W91CumLSr6tVaYIMD4KrVgqmiSIZXGhsk1jqwVDjxtStcxrfhazuSkucxq3iQjK/7vurejE9EPsG2mSsww4hNf5IPmDvk/PRFeqAAAAHicY2BiwA+YGJkYmRmYMg0YGAADDwCnAA==) format('woff');font-weight: normal;font-style: normal;}
    .excursionCard:hover .serviceCard__button:after {
        margin: 21px 0 -21px 21px;
    }
    .excursionCard:hover .button {
      color: #ED4D74;
    }
    .excursionCard:hover .button:after {
          color: #ED4D74;
      }
  .excursionCard__description {
    margin: 10px 0 20px;
    height: 60px;
    overflow: hidden;
  }
  @media(max-width: 570px) {
  .excursionCard__description {
        margin: 10px 0
  }
    }
  .excursionCard__details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 10px;
  }
  .excursionCard__detail {
    min-width: 45%;
    height: 40px;
    overflow: hidden;
  }
  .excursionCard__title {
    font-weight: 500;
    color: #333333;
  }
  .excursionCard__cover {
    height: 150px;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .excursionCard__button:after {
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Ic1f50;
    font-weight: normal;
    content: '\E000';
    font-size: 50px;
    margin: 21px 0 -21px 16px;
    color: #DDDFE0;
    -webkit-transition: all .1s ease-out;
    transition: all .1s ease-out;
  }