@font-face { font-family: xe9374; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAP4AAsAAAAABigAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPAAAAFYpoTZMY21hcAAAAYAAAABKAAABcOEoo6pnbHlmAAABzAAAAGkAAACASDRnC2hlYWQAAAI4AAAAMAAAADYnjg/1aGhlYQAAAmgAAAAZAAAAJABkAD9obXR4AAAChAAAAAgAAAAIADwAAGxvY2EAAAKMAAAABgAAAAYAQAAAbWF4cAAAApQAAAAeAAAAIAEOADxuYW1lAAACtAAAASkAAAIWm5e+CnBvc3QAAAPgAAAAFwAAACxqNwEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGSQY5zAwMrAwKDGoAwk2aG0BAMTAw8DkGBlZsAKAtJcUxgcHjA8YGAwAnJZgCRIJSNIDgDDHgVKeJztkLENgDAQA8/KQ4EYgSo1s1Cxv9gk+RjYIpbOkk9fPbAAJTmTAN2IkSut7AubfVB9E8M/tPZ37nCjlZndfXzLv3pBHRomCVEAAHicPY3BDkAwEER3S6tawgG9V1IHB6rVi///LGGRyCQzb+YywACuEzxpggXArMI6v4/WxXTgnzGFNHRixj5sb+4EKbrCMIYZPvY5YlNrlaunqFzKUhNJZMhpMMRlKznVseeVoO8b1CwK6AAAAHicY2BkYGAAYonZR6rj+W2+MnAzGAFFGJ7IvfRB0P//MhgCIQMDBwMTSBQAMNEKp3icY2BkYGAwYgABGzBpyMDIgAqYAA/nAKQAAAAAAAAAADwAAAAAAAAAQAAAeJxjYGRgYGBiMABiBjCLgYELCBkY/oP5DAANXgFLAAB4nGWQPW7CQBSEx2BIAlKCFCkps1UKIpmfkgNAT0GXwpi1MbK91npBossJcoQcIaeIcoIcKGPzaGAtP38zb97uygAG+IWHenm4bWq9WrihOnGb9CDsk5+FO+jjRbhLfyjcwxumwn084p07eP4dnQFK4Rbu8SHcpv8p7JO/hDt4wrdwl/6PcA8r/An38eoN08gUsSncUif7LLRnef6utK1SU6hJMD5bC11oGzq9Ueujqg7J1LlYxdbkas6uzjKjSmt2OnLB1rlyNhrF4geRyZEigkGBuKkOS2gk2CNDCHvVvdQrpi0q+rVWmCDA+Cq1YKpokiGVxobJNY6sFQ48bUrXMa34Ws7kpLnMat4kIyv+77q3oxPRD7BtpkrMMOITX+SD5g75Pz0RXqgAAAB4nGNgYsAPmBiZGJkZmDINGBgAAw8ApwA=) format('woff');font-weight: normal;font-style: normal;}
  .room__price {
  color: #ffffff;
  font-family: 'Kinetika';
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  margin-top: 4px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}
  .room__price > span {
    font-size: 34px;
  }
  .room__price:after {
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: xe9374;
    font-weight: normal;
    content: '\E000';
    font-size: 16px;
    font-weight: 900;
    color: #ffffff;
  }