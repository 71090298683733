@font-face { font-family: K4fd69; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAOoAAsAAAAABdAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAOAAAAFYpODXkY21hcAAAAXwAAABKAAABcOEoo6pnbHlmAAAByAAAACgAAAAoI1KXgmhlYWQAAAHwAAAALAAAADYncA+naGhlYQAAAhwAAAAWAAAAJAApABdobXR4AAACNAAAAAgAAAAIABQAAGxvY2EAAAI8AAAABgAAAAYAFAAAbWF4cAAAAkQAAAAeAAAAIAENABVuYW1lAAACZAAAASkAAAIWm5e+CnBvc3QAAAOQAAAAFwAAACxqNwEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGTgYpzAwMrAwMDDwAckmaA0JxCzgriszAxYQUCaawqDwwOGBwwMIkAuI5gE0UAAAJhpBHl4nO2QsQ2AMBADz8pDgRiBKjWzULG/2CT5GNgils6ST189sAAlOZMA3YiRK63sC5t9UH0Twz+09nfucKOVmd19fMu/ekEdGiYJUQAAAAEAAAAAABQADgAIAAA3BxcjFTMHFzcPAQIODgIBBA0BAgICAQQAAHicY2BkYGAA4pTejPB4fpuvDNwMIkARhidyL12QaaAoH5DkYGACcQD+0Qf7eJxjYGRgYBBhYEAiGRlQARMABjYAQQAAAAAAAAAUAAAAAAAAABQAAHicY2BkYGBgYuBkANEgFgMDFxAyMPwH8xkACXgBIwAAeJxlkD1uwkAUhMdgSAJSghQpKbNVCiKZn5IDQE9Bl8KYtTGyvdZ6QaLLCXKEHCGniHKCHChj82hgLT9/M2/e7soABviFh3p5uG1qvVq4oTpxm/Qg7JOfhTvo40W4S38o3MMbpsJ9POKdO3j+HZ0BSuEW7vEh3Kb/KeyTv4Q7eMK3cJf+j3APK/wJ9/HqDdPIFLEp3FIn+yy0Z3n+rrStUlOoSTA+WwtdaBs6vVHro6oOydS5WMXW5GrOrs4yo0prdjpywda5cjYaxeIHkcmRIoJBgbipDktoJNgjQwh71b3UK6YtKvq1VpggwPgqtWCqaJIhlcaGyTWOrBUOPG1K1zGt+FrO5KS5zGreJCMr/u+6t6MT0Q+wbaZKzDDiE1/kg+YO+T89EV6oAAAAeJxjYGLAD5gYmRiZGZgyDRgYAAMPAKcA) format('woff');font-weight: normal;font-style: normal;}
  .image-gallery-cover {
  -o-object-fit: cover;
     object-fit: cover;
  max-height: 450px;
  max-width: 100%;
  border-radius: 8px;
  -webkit-box-shadow: 0 5px 15px 0 rgb(142 143 147 / 20%);
          box-shadow: 0 5px 15px 0 rgb(142 143 147 / 20%);
}
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
.image-gallery-cover {
    max-width: 50%
}
  }

.video-image-gallery .image-gallery-slide {
    background: black;
  }

.video-image-gallery .play-button {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 60px;
    width: 60px;
    background-color: rgba(255,255,255, .9);
    -webkit-box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.12);
            box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.12);
    border-radius: 50px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }

.video-image-gallery .play-button:hover {
      background-color:rgba(255,255,255, 1);
    }

.video-image-gallery .play-button:after {
      content: "";
      display: block;
      position: absolute;
      top: 19px;
      left: 25px;
      margin: 0 auto;
      border-style: solid;
      border-width: 10.5px 0 10.5px 15px;
      border-color: transparent transparent transparent #6CBBE7;
    }

.image-gallery-slides {
  border-radius: 8px;
}

.image-gallery-index {
  border-top-right-radius: 8px;
}

.image-gallery-slide .image-gallery-description {
  background: none;
  bottom: 10px;
  left: 10px;
  z-index: 1;
  font-size: 14px;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  text-align: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  margin-top: 24px;
}

.image-gallery {
  max-width: 920px;
  width: 100%;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  background: transparent;
}

.image-gallery.fullscreen-modal {
    max-width: 100%;
  }

.image-gallery .image-gallery-left-nav {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px; 
  }

.image-gallery .image-gallery-left-nav:after {
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: K4fd69;
      font-weight: normal;
      content: '\E000';
      color: #6CBBE7;
      -webkit-transition: all .1s ease-out;
      transition: all .1s ease-out;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      display: block;
    }

.image-gallery .image-gallery-right-nav {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px; 
  }

.image-gallery .image-gallery-right-nav:after {
      display: block;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: K4fd69;
      font-weight: normal;
      content: '\E000';
      color: #6CBBE7;
      -webkit-transition: all .1s ease-out;
      transition: all .1s ease-out;
    }

.image-gallery .image-gallery-left-nav, .image-gallery .image-gallery-right-nav {
    background: rgba(255,255,255, .9);
    font-size: 28px;
    padding: 0;
    width: 72px;
    height: 60px;
    -webkit-box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.12);
            box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.12);
  }

@media(max-width: 769px) {

.image-gallery .image-gallery-left-nav, .image-gallery .image-gallery-right-nav {
      width: 48px;
      height: 38px;
      font-size: 18px
  }
    }

.image-gallery .image-gallery-left-nav:before, .image-gallery .image-gallery-right-nav:before {
      display: none;
    }

.image-gallery .image-gallery-left-nav:hover::before {
      color: #ffffff;
    }

.image-gallery .image-gallery-right-nav:hover::before {
      color: #ffffff;
    }

.image-gallery .image-gallery-image {
    max-height: 520px;
    height: 100%;
    background: black;
  }

.image-gallery .image-gallery-image img {
      height: 520px;
      -o-object-position: center;
         object-position: center;
      -o-object-fit: cover;
         object-fit: cover;
      width: 100%;
      min-width: 100%;
    }

.image-gallery .vertical-image .image-gallery-image img {
      -o-object-position: center;
         object-position: center;
      -o-object-fit: contain;
         object-fit: contain;
    }

.image-gallery .image-gallery-slide {
    background: transparent;
  }

.image-gallery .image-gallery-thumbnails {
    padding: .5em 0;
  }

.image-gallery .image-gallery-thumbnail {
    width: 72px;
    height: 44px;
    border: none;
    margin-right: .5em;
    position: relative;
    opacity: .3;
    -webkit-transition: .3s ease;
    transition: .3s ease;
  }

.image-gallery .image-gallery-thumbnail img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: center;
         object-position: center;
    }

.image-gallery .image-gallery-thumbnail.active {
      border: none;
      opacity: 1;
    }

.image-gallery .image-gallery-thumbnail.active:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }

.image-gallery-slide-wrapper {
  margin: 0;
  -webkit-box-shadow: 0 5px 15px 0 rgba(68, 149, 206, 0.2);
          box-shadow: 0 5px 15px 0 rgba(68, 149, 206, 0.2);
  border-radius: 8px;
}

/*****==== FullScreen ====*****/

.image-gallery .image-gallery-content.fullscreen {
  font-size: 16px;
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 4;
  vertical-align: middle;
}

.image-gallery .image-gallery-content.fullscreen .image-gallery-image {
    height: 100%;
    max-height: none;
  }

.image-gallery .image-gallery-content.fullscreen .image-gallery-image img {
      height: 100vh;
      width: 100%;
      -o-object-position: center;
         object-position: center;
      -o-object-fit: contain;
         object-fit: contain;
    }

@media(max-width: 769px) {

.image-gallery .image-gallery-content.fullscreen .image-gallery-image img {
        -o-object-fit: contain;
           object-fit: contain
    }
      }

.image-gallery .image-gallery-content.fullscreen .image-gallery-thumbnail {
    display: none;
  }

.image-gallery .image-gallery-content.fullscreen .image-gallery-fullscreen-button {
    margin-bottom: 5em;
  }

.iq_gallery__close {
  position: fixed;
  right: 1.7em;
  top: 1.7em;
  color: #ffffff;
  z-index: 99;
  font-size: 1.3em;
}

@media(max-width: 996px) {
  .image-gallery-cover {
    max-height: 350px;
  }
      .image-gallery .image-gallery-image img {
        height: 350px;
      }
}

@media (max-width: 769px) {
  .image-gallery-thumbnails {
      display: none;
  }
  .image-gallery-cover {
    max-height: 300px;
  }
  .image-gallery .image-gallery-image {
    height: 300px;
    background: black;
  }
    .image-gallery .image-gallery-image img {
      height: 300px;
    }
}

@media (max-width: 570px) {
  .image-gallery-cover {
    max-height: 235px;
  }
  .image-gallery .image-gallery-image {
    height: 235px;
    background: black;
  }
    .image-gallery .image-gallery-image img {
      height: 235px;
    }
}

@media (min-width: 1280px) {
  .image-gallery .image-gallery-content.fullscreen {
    width: 100%;
  }
}