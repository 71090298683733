@font-face { font-family: H1b839; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAO8AAsAAAAABdAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPQAAAFYqRzbGY21hcAAAAYQAAABKAAABcOEoo6pnbHlmAAAB0AAAACgAAAAoTOokF2hlYWQAAAH4AAAALgAAADYn5xBRaGhlYQAAAigAAAAbAAAAJADxAEBobXR4AAACRAAAAAgAAAAIAGQAAGxvY2EAAAJMAAAABgAAAAYAFAAAbWF4cAAAAlQAAAAeAAAAIAENABVuYW1lAAACdAAAASkAAAIWm5e+CnBvc3QAAAOgAAAAGwAAACxqOAEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGQwYpzAwMrAwGDP4AYk+aC0AQMLgyQDAxMDKzMDVhCQ5prC4PCA4QEDQwqQywkmGRgYQQQA+uoGagAAAHic7ZCxDYAwEAPPykOBGIEqNbNQsb/YJPkY2CKWzpJPXz2wACU5kwDdiJErrewLm31QfRPDP7T2d+5wo5WZ3X18y796QR0aJglRAAAAAQAAAAAAiwBiAAgAADcHFyMVMwcXN2kKEG9vEAoiYgoRDhAKIQAAeJxjYGRgYABiHgXvw/H8Nl8ZuBlSgCIMT+ReuiPTDN0MSUCSg4EJxAEA/iEIpgAAeJxjYGRgYEhhYICQ/28ydDMwMqACJgBIQgMwAAAAAAAAZAAAAAAAAAAUAAB4nGNgZGBgYGLgZADRIBYDAxcQMjD8B/MZAAl4ASMAAHicZZA9bsJAFITHYEgCUoIUKSmzVQoimZ+SA0BPQZfCmLUxsr3WekGiywlyhBwhp4hyghwoY/NoYC0/fzNv3u7KAAb4hYd6ebhtar1auKE6cZv0IOyTn4U76ONFuEt/KNzDG6bCfTzinTt4/h2dAUrhFu7xIdym/ynsk7+EO3jCt3CX/o9wDyv8Cffx6g3TyBSxKdxSJ/sstGd5/q60rVJTqEkwPlsLXWgbOr1R66OqDsnUuVjF1uRqzq7OMqNKa3Y6csHWuXI2GsXiB5HJkSKCQYG4qQ5LaCTYI0MIe9W91CumLSr6tVaYIMD4KrVgqmiSIZXGhsk1jqwVDjxtStcxrfhazuSkucxq3iQjK/7vurejE9EPsG2mSsww4hNf5IPmDvk/PRFeqAAAAHicY2BigABGBuyAiZGJkZmBKdOAgQEAAzIAqAA=) format('woff');font-weight: normal;font-style: normal;}

  .section {
  margin: 40px 0 50px;
  width: 100%;
  position: relative;
}

  .section.section--hidden {
    overflow: hidden;
  }

  /**Bg color**/

  .section.section--grey {
    background: #F5F5F7;
  }

  .section.section--fitted_true {
    padding: 0;
    margin: 0;
  }

  .section.section--fitted_padding {
    padding: 0;
  }

  .section.section--fitted_margin {
    margin: 0;
  }

  .section.section--text > .container {
      max-width: 900px;
    }

@media(max-width: 769px) {
  .wrapper--bg_white {
    padding: 2em;
  }
}

.section__title {
  font-weight: 900;
  font-size: 2.3em;
  line-height: 1em;
  font-family: 'Noah';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-decoration: none;
  color: #272729;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 25px;
}

.section__title--inverted{
    color: #ffffff;
  }

.section__title > a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    line-height: 1em;
    cursor: pointer;
    border: none;
    outline: none;
    font-family: 'Roboto';
    font-size: 17px;
    font-weight: 400;
    padding-top: 5px;
    color: #272729;
    margin-right: 5%;
    text-decoration: none;
  }

.section__title > a:after {
      position: relative;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: H1b839;
      font-weight: normal;
      content: '\E000';
      font-size: 10px;
      color: #272729;
      -webkit-transition: all .1s ease-out;
      transition: all .1s ease-out;
      left: 10px;
      top: 3px;
    }

.section__title > a:hover {
      color: #6CBBE7;
    }

.section__title > a:hover:after {
        color: #6CBBE7;
        left: 20px;
      }